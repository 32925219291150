import axios from 'axios'

const blobToBase64 = blob =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = () => {
      const base64data = reader.result
      resolve(base64data.split(',')[1])
    }
    reader.onerror = error => {
      reject(error)
    }
  })

const prepareAttachments = async files => {
  console.log(
    '🚀 ~ file: emailService.js:17 ~ prepareAttachments ~ files:',
    files
  )

  const attachments = await Promise.all(
    files.map(async (file, index) => {
      const content = await blobToBase64(file)
      return {
        filename: file.filename || `Dokument_${index + 1}.pdf`,
        content,
        type: 'application/pdf',
        disposition: 'attachment',
      }
    })
  )

  return attachments
}

const sendEmail = async email => {
  console.log('🚀 ~ file: emailService.js:38 ~ sendEmail ~ email:', email)

  try {
    // let attachments = []

    const emailData = {
      to: email.to,
      subject: email.subject,
      text: email.text,
      template_id: email.template_id,
      dynamic_template_data: email.dynamic_template_data,
    }

    if (email.attachments && email.attachments.length > 0) {
      emailData.attachments = await prepareAttachments(email.attachments)
    }

    const response = await axios.post(
      'https://mail.der-lagermeister.de/send-email',
      emailData
    )

    // if (email.attachments.length > 0) {
    //   attachments = await prepareAttachments(email.attachments)
    // }

    // const response = await axios.post('http://localhost:3000/send-email', {
    //   to: email.to,
    //   subject: email.subject,
    //   text: email.text,
    //   attachments,
    //   template_id: email.template_id,
    //   dynamic_template_data: email.dynamic_template_data,
    // })

    if (response.status === 200) {
      return { success: true, message: 'Email sent successfully' }
    }
    return { success: false, message: 'Error sending email' }
  } catch (error) {
    console.error('Fehler beim Senden der E-Mail:', error)
    return { success: false, message: error.message || 'Unbekannter Fehler' }
  }
}

export default sendEmail
