export default function inquiriesHelper() {
     function getButtonVariant(item) {
      if (item.qualification === 'Akzeptiert') {
        return 'success'
      }
      if (item.qualification === 'Abgelehnt') {
        return 'danger'
      }
      if (
        // eslint-disable-next-line operator-linebreak
        item.qualification === 'Unbekannt' ||
        item.qualification === 'Sonstiges'
      ) {
        return 'warning'
      }

      return 'primary'
     }
  
  function getStatusVariant(item) {
      if (item.offers.length > 0) {
        return 'success'
      }
      return 'danger'
     }
  
  function isOfferable(item) {
    if (getButtonVariant(item) === 'success') {
        return true
    }
    return false
  }

    return {
        getButtonVariant, isOfferable, getStatusVariant
    }
}
