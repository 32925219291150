export default {
  qualificationOptions: ['Akzeptiert', 'Abgelehnt', 'Sonstiges', 'Unbekannt'],

  getStatus(item) {
    // return true if offer is not null
    if (item.offers.length > 0) {
      return 'Angebot versendet'
    }
    if (item.status === 'rejected') {
      return 'Abgelehnt versendet'
    }
    return 'Offen'
  },

  getOption(option) {
    // return true if offer is not null
    if (option === 'Akzeptiert') {
      return 'accept'
    }
    if (option === 'Abgelehnt') {
      return 'deny'
    }
    if (option === 'Sonstiges') {
      return 'other'
    }
    return 'unknown'
  },
}
