<template lang="">
  <div>
    <!-- {{ inquiriesEntries }} -->
    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Zeige</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>Einträge</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="filter"
                class="d-inline-block mr-1"
                placeholder="Suche..."
                type="search"
              />
              <b-button variant="primary" @click="createTestInquiry()">
                <span class="text-nowrap">Testanfrage generieren</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refListTable"
        striped
        hover
        :current-page="currentPage"
        :per-page="perPage"
        :items="inquiriesEntries"
        :fields="fields"
        :filter="filter"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        responsive
        show-empty
        empty-text="Keine Einträge gefunden"
        @row-clicked="onRowClicked"
      >
        <template #cell(acceptInquiry)="{ item }">
          <b-button
            :disabled="item.offers.length > 0"
            :variant="getButtonVariant(item)"
            @click="showQualificationModal(item)"
          >
            <feather-icon icon="AlignJustifyIcon" class="mr-50" />
            <span class="align-middle">{{
              item.qualification ? item.qualification : 'Qualifizieren'
            }}</span>
          </b-button>
        </template>

        <template #cell(status)="{ item }">
          <div>
            <b-badge :variant="getStatusVariant(item)">
              {{ inquiry.getStatus(item) }}
            </b-badge>
          </div>
        </template>

        <template #cell(storageLocation)="{ item }">
          <div>
            {{ locationById(item.storageLocation).name }}
          </div>
        </template>

        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret>
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              :to="{
                name: 'inquiries-view',
                params: { id: data.item.id },
              }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Öffnen</span>
            </b-dropdown-item>

            <!-- <b-dropdown-item @click="editObject(data)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Bearbeiten</span>
            </b-dropdown-item> -->

            <b-dropdown-item
              v-if="data.item.qualification === 'Abgelehnt'"
              @click="deleteWarning(data)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Löschen</span>
            </b-dropdown-item>
          </b-dropdown>
        </template></b-table
      >
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Zeige {{ dataMeta.from }} bis {{ dataMeta.to }} von
              {{ dataMeta.of }} Einträge</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="getTotalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <InquiriesQualificationModal
      ref="modal-qualifaction"
      :selected-option="selectedOption"
      :qualification-options="qualificationOptions"
      :selected-inquiry="selectedInquiry"
    />

    <!-- <b-modal
      ref="modal-qualifaction"
      centered
      title="Qualifizieren"
      ok-title="Ok"
      cancel-title="Abbrechen"
      cancel-variant="outline-secondary"
      :no-close-on-backdrop="true"
      @ok="qualificationSubmitted"
    >
      Anfrage qualifizieren.

      <b-form>
        <b-form-group label-for="vue-select">
          <v-select
            v-model="this.selectedOption"
            :clearable="false"
            :options="this.qualificationOptions"
            placeholder="Auswahl"
            @option:selected="onChange($event)"
          />
        </b-form-group>
      </b-form>
    </b-modal> -->
  </div>
</template>
<script>
import Inquiry from '@/store/inquiry/Inquiry'
import vSelect from 'vue-select'

import {
  BCard,
  VBModal,
  BModal,
  BTable,
  BRow,
  BCol,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
  VBToggle,
  BDropdownDivider,
  BFormSelect,
  BButton,
  BPagination,
  BFormInput,
  BBadge,
} from 'bootstrap-vue'
import inquiriesHelper from '@/helper/inquiriesHelper'
import InquiriesQualificationModal from '../modal/InquiriesQualificationModal.vue'
import tableHelper from '../../Table+Helper'

export default {
  setup() {
    const { getButtonVariant, getStatusVariant } = inquiriesHelper()

    const {
      perPageOptions,
      perPage,
      filter,
      totalRows,
      currentPage,
      refListTable,
      dataMeta,
      refetchData,
    } = tableHelper()

    return {
      getButtonVariant,
      getStatusVariant,
      perPageOptions,
      perPage,
      filter,
      totalRows,
      currentPage,
      refListTable,
      dataMeta,
      refetchData,
    }
  },
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BPagination,
    VBModal,
    BModal,
    VBToggle,
    Inquiry,
    vSelect,
    BDropdownDivider,
    BFormSelect,
    BButton,
    inquiriesHelper,
    BFormInput,
    BBadge,
    InquiriesQualificationModal,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-modal': VBModal,
  },
  data() {
    return {
      sortBy: 'id',
      sortDesc: true,
      qualificationOptions: Inquiry.qualificationOptions,
      inquiry: Inquiry,
      selectedInquiry: {},
      selectedOption: null,
      fields: [
        {
          key: 'id',
          label: 'ID',
          sortable: true,
        },
        {
          key: 'acceptInquiry',
          label: 'Bewertung',
          sortable: true,
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true,
        },
        {
          key: 'firstName',
          label: 'Vorname',
          sortable: true,
        },
        {
          key: 'lastName',
          label: 'Nachname',
          sortable: 'true',
        },
        {
          key: 'email',
          label: 'Email',
          sortable: true,
        },
        {
          key: 'storageLocation',
          label: 'Lager Standort',
          sortable: true,
        },

        {
          key: 'message',
          label: 'Nachricht',
          sortable: true,
        },
        {
          key: 'actions',
          label: 'Aktionen',
        },
      ],
    }
  },
  computed: {
    inquiriesEntries() {
      return this.$store.getters.inquiriesAllEntries
    },
    getTotalRows() {
      return this.inquiriesEntries.length
    },
  },
  updated() {
    this.totalRows = this.getTotalRows
  },
  methods: {
    locationById(id) {
      return this.$store.getters.locationById(Number(id))
    },

    onRowClicked(item) {
      this.$router.push(`/inquiries-view/${item.id}`)
    },

    accteptedInquiryChanged(item) {
      console.log(item)
      this.$store.dispatch('updateInquiry', item)
      // this.inquiriesEntries()
    },

    deleteWarning(data) {
      this.$swal({
        title: 'Bist du sicher?',
        // text: 'Das Lager wird unwiederruflich gelöscht.',
        text: `Die Anfrage von "${data.item.firstName} ${data.item.lastName} mit der ID ${data.item.id} wirklich löschen?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ja, löschen!',
        cancelButtonText: 'Abbrechen',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          // this.$store.dispatch('deleteCustomer', data.item.id)
          this.$router.go()
        }
      })
    },

    showQualificationModal(item) {
      // alert('showQualificationModal')
      this.selectedInquiry = item
      this.selectedOption = item.qualification
      this.$refs['modal-qualifaction'].show()
    },

    qualificationSubmitted() {
      // alert(this.selectedInquiry.qualification)

      this.selectedInquiry.qualification = this.selectedOption

      this.$store.dispatch('updateInquiry', this.selectedInquiry)
    },

    onChange(events) {
      this.selectedOption = events
    },

    createTestInquiry() {
      const testInquiry = this.generateTestInquiry()
      this.$store
        .dispatch('addInquiry', testInquiry)
        .then(() => {
          this.$store.dispatch('getInquiriesEntries')
        })
        .catch(error => {
          console.log(error)
        })
    },

    generateTestInquiry() {
      const salutations = {
        male: ['Herr'],
        female: ['Frau'],
      }
      const firstNames = {
        male: ['Benjamin', 'David', 'Fabian', 'Jan', 'Lukas', 'Max'],
        female: ['Anna', 'Caroline', 'Emilia', 'Hannah', 'Lea', 'Sophie'],
      }
      const lastNames = [
        'Müller',
        'Schmidt',
        'Schneider',
        'Fischer',
        'Weber',
        'Mayer',
      ]
      const messages = [
        'Ich bin an Ihren Lagermöglichkeiten interessiert.',
        'Können Sie mir bitte ein Angebot schicken?',
      ]
      const streets = [
        'Hauptstraße',
        'Bahnhofstraße',
        'Kirchstraße',
        'Mühlenweg',
      ]
      // const cities = [
      //   'Berlin',
      //   'Hamburg',
      //   'München',
      //   'Köln',
      //   'Frankfurt',
      //   'Stuttgart',
      // ]
      // const zipCodes = ['10115', '20095', '80331', '50667', '60311', '70173']
      const phoneNumbers = ['0176-12345678', '0157-23456789', '0162-34567890']
      const leadSources = ['Website', 'Empfehlung', 'Werbung', 'Messe']
      // const storageLocations = [
      //   'Lagerhalle 1',
      //   'Lagerhalle 2',
      //   'Lagerhalle 3',
      //   'Lagercontainer',
      // ]
      const organizationNames = ['Firma A', 'Firma B', 'Firma C']
      const country = 'Deutschland'

      const gender = Math.random() < 0.5 ? 'male' : 'female'
      // eslint-disable-next-line operator-linebreak
      const salutation =
        salutations[gender][
          Math.floor(Math.random() * salutations[gender].length)
        ]
      // eslint-disable-next-line operator-linebreak
      const firstName =
        firstNames[gender][
          Math.floor(Math.random() * firstNames[gender].length)
        ]
      const lastName = lastNames[Math.floor(Math.random() * lastNames.length)]
      const message = messages[Math.floor(Math.random() * messages.length)]
      const email = `${firstName.toLowerCase()}.${lastName.toLowerCase()}@example.com`
      const streetNumber = Math.floor(Math.random() * 100) + 1
      const streetName = streets[Math.floor(Math.random() * streets.length)]
      const city = 'Münster'
      const zipCode = '48157'
      // eslint-disable-next-line operator-linebreak
      const phoneNumber =
        phoneNumbers[Math.floor(Math.random() * phoneNumbers.length)]
      // eslint-disable-next-line operator-linebreak
      const leadSource =
        leadSources[Math.floor(Math.random() * leadSources.length)]
      // eslint-disable-next-line operator-linebreak
      const storageLocation = '1'
      const estimatedStartRent = new Date().toISOString().slice(0, 10)
      const estimatedEndRent = new Date(
        new Date().getTime() + 7 * 24 * 60 * 60 * 1000
      )
        .toISOString()
        .slice(0, 10)
      // eslint-disable-next-line operator-linebreak
      const organizationName =
        organizationNames[Math.floor(Math.random() * organizationNames.length)]
      const priceClassicStorage = Math.floor(Math.random() * 100)
      const priceDeluxeStorage = Math.floor(Math.random() * 100)
      const numberClassicStorage = 1
      const numberDeluxeStorage = 1

      const storageJSON = {
        storage: [
          {
            id: 1,
            type: 'Classic Lagercontainer',
            priceStorage: '129',
            numberStorage: 1,
            estimatedEndRent: '3 Monate',
            estimatedStartRent: '01.03.2022',
          },
          {
            id: 2,
            type: 'Deluxe Lagercontainer',
            priceStorage: '189',
            numberStorage: 1,
            estimatedEndRent: '6 Monate',
            estimatedStartRent: '01.03.2022',
          },
        ],
      }
      const acceptInquiry = false
      const qualification = null
      const status = 'open'
      const offers = []
      const isOrganization = Math.random() < 0.5
      // eslint-disable-next-line camelcase
      const created_by = 'Test User'
      // eslint-disable-next-line camelcase
      const updated_by = 'Test User'

      return {
        firstName,
        lastName,
        message,
        email,
        salutation,
        street: `${streetName} ${streetNumber}`,
        city,
        zipCode,
        phoneNumber,
        leadSource,
        storageLocation,
        estimatedStartRent,
        estimatedEndRent,
        organizationName,
        priceClassicStorage,
        priceDeluxeStorage,
        numberClassicStorage,
        numberDeluxeStorage,
        storageJSON,
        acceptInquiry,
        qualification,
        status,
        offers,
        isOrganization,
        country,
        created_by,
        updated_by,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
