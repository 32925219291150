<template lang="">
  <div>
    <b-modal
      ref="modal-qualifaction"
      centered
      title="Qualifizieren"
      ok-title="Ok"
      cancel-title="Abbrechen"
      cancel-variant="outline-secondary"
      :no-close-on-backdrop="true"
      @ok="qualificationSubmitted"
    >
      Anfrage qualifizieren.

      <b-form>
        <b-form-group label-for="vue-select">
          <v-select
            v-model="this.selectedOption"
            :clearable="false"
            :options="this.qualificationOptions"
            placeholder="Auswahl"
            @option:selected="onChange($event)"
          />
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal
      id="modal-confirm-email"
      ref="modalConfirmEmail"
      centered
      title="E-Mail Bestätigung"
      ok-title="Ja, senden"
      cancel-title="Nein"
      @ok="sendEmail"
    >
      Möchten Sie eine Ausgebucht-Benachrichtigung per E-Mail senden?
    </b-modal>
  </div>
</template>
<script>
import {
  BCard,
  VBModal,
  BModal,
  BTable,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
  VBToggle,
  BDropdownDivider,
  BFormSelect,
  BButton,
  BPagination,
  BFormInput,
  BBadge,
} from 'bootstrap-vue'

import Inquiry from '@/store/inquiry/Inquiry'
import sendEmail from '@/service/emailService'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BPagination,
    VBModal,
    BModal,
    VBToggle,
    // Inquiry,
    vSelect,
    BDropdownDivider,
    BFormSelect,
    BButton,
    // inquiriesHelper,
    BFormInput,
    BBadge,
    BForm,
    BFormGroup,
  },

  directives: {
    'b-toggle': VBToggle,
    'b-modal': VBModal,
  },

  props: {
    selectedInquiry: {
      type: Object,
      required: true,
    },
    selectedOption: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      qualificationOptions: Inquiry.qualificationOptions,
      inquiry: Inquiry,
    }
  },

  methods: {
    show() {
      this.$refs['modal-qualifaction'].show()
    },

    onChange(events) {
      this.selectedOption = events
    },

    // showQualificationModal(item) {
    //   // alert('showQualificationModal')
    //   this.selectedInquiry = item
    //   this.selectedOption = item.qualification
    //   this.$refs['modal-qualifaction'].show()
    // },

    qualificationSubmitted() {
      this.selectedInquiry.qualification = this.selectedOption
      this.$store.dispatch('updateInquiry', this.selectedInquiry)

      if (this.selectedInquiry.qualification === 'Abgelehnt') {
        this.$refs.modalConfirmEmail.show()
      }
    },

    sendEmail() {
      // Logik zum Senden der E-Mail
      console.log('E-Mail wird gesendet...')
      // Anschließend die Anfrage im Store aktualisieren
      this.onSendEmail(this.selectedInquiry)
    },

    async onSendEmail(selectedInquiry) {
      // const emailType = 'offer'
      // const emailBody = emailStoragesBody(offer.storages, emailType)

      const email = {
        to: selectedInquiry.email,
        subject: 'Der Lagermeister',
        text: 'Hallo',
        template_id: 'd-0163bafb1d0c4ffca6b2ad5f9f1cccc6',
        dynamic_template_data: {
          salutation: selectedInquiry.salutation,
          lastName: selectedInquiry.lastName,
        },
      }

      const result = await sendEmail(email)

      if (result.success) {
        this.selectedInquiry.status = 'rejected'
        this.$store.dispatch('updateInquiry', this.selectedInquiry)
        alert(result.message)
      } else {
        alert(result.message)
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
